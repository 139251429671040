import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 border-b border-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    {/* Logo */}
                    <div className="flex items-center gap-2">
                        <span className="text-2xl font-bold text-blue-600">Suplo</span>
                    </div>

                    {/* Navigation Links */}
                    <div className="hidden md:flex items-center gap-8">
                        <a href="#features" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Características
                        </a>
                        <a href="#product" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Producto
                        </a>
                        <a href="#how-it-works" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Cómo Funciona
                        </a>
                        <a href="#pricing" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Precios
                        </a>
                        <button
                            onClick={() => navigate('/login')}
                            className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-blue-700 transition-all transform hover:scale-105 shadow-md hover:shadow-lg flex items-center gap-2 group"
                        >
                            Iniciar Sesión
                            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header; 