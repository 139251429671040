import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import product1 from '../assets/product1.png';
import product2 from '../assets/product2.png';
import product3 from '../assets/product3.png';

const Product = () => {
    return (
        <section className="py-24 bg-gray-50" id="product">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-16">
                    <h2 className="text-4xl font-bold text-gray-900 mb-4">
                        Una plataforma completa para tus integraciones B2B
                    </h2>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        Gestiona, monitorea y accede a tus datos de manera eficiente con nuestras herramientas especializadas
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 gap-8">
                    {/* Integraciones */}
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow h-full flex flex-col">
                        <h3 className="text-2xl font-bold text-gray-900 p-8 pb-4">Panel de Integraciones</h3>
                        <div className="bg-gray-100 px-4 flex justify-center items-center lg:h-[200px]">
                            <img
                                src={product1}
                                alt="Panel de Integraciones"
                                className="object-contain w-full h-full"
                            />
                        </div>
                        <div className="p-8 flex flex-col flex-1 justify-between">
                            <p className="text-gray-600 mb-6">
                                Administra todas tus conexiones B2B desde un solo lugar. Crea, edita y monitorea el estado de tus integraciones fácilmente.
                            </p>
                            <ul className="space-y-3">
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Gestión centralizada de credenciales</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Monitoreo de estado en tiempo real</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Configuración flexible de retailers</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Sincronizaciones */}
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow h-full flex flex-col">
                        <h3 className="text-2xl font-bold text-gray-900 p-8 pb-4">Detalles de Sincronización</h3>
                        <div className="bg-gray-100 px-4 flex justify-center items-center lg:h-[200px]">
                            <img
                                src={product2}
                                alt="Detalles de Sincronización"
                                className="object-contain w-full h-full"
                            />
                        </div>
                        <div className="p-8 flex flex-col flex-1 justify-between">
                            <p className="text-gray-600 mb-6">
                                Visualiza el historial completo de sincronizaciones y resuelve problemas rápidamente con nuestro sistema de alertas.
                            </p>
                            <ul className="space-y-3">
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Historial detallado de actualizaciones</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Alertas de errores y problemas</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Estado de sincronización en vivo</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Documentación */}
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow h-full flex flex-col">
                        <h3 className="text-2xl font-bold text-gray-900 p-8 pb-4">Documentación SQL</h3>
                        <div className="bg-gray-100 px-4 flex justify-center items-center lg:h-[200px]">
                            <img
                                src={product3}
                                alt="Documentación SQL"
                                className="object-contain w-full h-full"
                            />
                        </div>
                        <div className="p-8 flex flex-col flex-1 justify-between">
                            <p className="text-gray-600 mb-6">
                                Accede a documentación detallada de todas las tablas y campos disponibles para tus análisis y reportes.
                            </p>
                            <ul className="space-y-3">
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Descripción detallada de tablas</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Ejemplos de consultas SQL</span>
                                </li>
                                <li className="flex items-center gap-2 text-gray-600">
                                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                                    <span>Guías de mejores prácticas</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Product; 