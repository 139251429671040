import React, { useState } from 'react';
import {
    ArrowRight,
    Database,
    BarChart3,
    Zap,
    CheckCircle2,
} from 'lucide-react';
import Header from './components/Header';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Snackbar, Alert, Button } from '@mui/material';
import * as S from './styles/LandingStyles';
import Product from './components/Product';

const features = [
    {
        title: 'Administrador de Integraciones',
        description: 'Edita credenciales, monitorea actualizaciones y agrega nuevas conexiones fácilmente.',
    },
    {
        title: 'Data consolidada en SQL',
        description: 'Consulta tablas transformadas y accede a documentación en vivo para tus reportes.',
    },
    {
        title: 'Multiempresa',
        description: 'Gestiona múltiples clientes e integraciones desde una misma plataforma.',
    },
];

const LandingV2 = () => {
    const navigate = useNavigate();
    const [demoDialogOpen, setDemoDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [demoForm, setDemoForm] = useState({
        name: '',
        email: '',
        company: '',
        message: '',
    });

    const handleDemoRequest = (event: React.FormEvent) => {
        event.preventDefault();
        console.log('Demo requested:', demoForm);
        setDemoDialogOpen(false);
        setSnackbarOpen(true);
        setDemoForm({
            name: '',
            email: '',
            company: '',
            message: '',
        });
    };

    const handleDemoFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setDemoForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
            <Header />

            {/* Hero Section */}
            <header className="relative overflow-hidden pt-16">
                <div className="absolute inset-0 bg-blue-50/50 z-0" />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 relative z-10">
                    <div className="text-center">
                        <h1 className="text-5xl font-bold text-gray-900 mb-6">
                            Conecta tus datos B2B y potencia tus decisiones con{' '}
                            <span className="text-blue-600">Suplo</span>
                        </h1>
                        <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                            Simplificamos las integraciones para que te enfoques en ofrecer reportes de valor
                        </p>
                        <button
                            onClick={() => setDemoDialogOpen(true)}
                            className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-blue-700 transition-all transform hover:scale-105 shadow-md hover:shadow-lg flex items-center gap-2 group mx-auto"
                        >
                            Solicitar una Demo
                            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                        </button>
                    </div>
                </div>
            </header>

            {/* What is Suplo Section (Features) */}
            <section className="py-20 bg-white" id="features">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-gray-900 mb-4">
                            La solución que integra y transforma tus datos B2B
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Suplo centraliza y transforma la información clave de portales B2B de retail, consolidando ventas e inventario en una base de datos lista para análisis.
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="p-6 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors">
                            <Database className="w-12 h-12 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Integraciones Automáticas</h3>
                            <p className="text-gray-600">
                                Conexión directa con los principales retailers y supermercados de Chile
                            </p>
                        </div>
                        <div className="p-6 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors">
                            <BarChart3 className="w-12 h-12 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Data Consolidada</h3>
                            <p className="text-gray-600">
                                Ventas e inventario unificados en una base SQL optimizada
                            </p>
                        </div>
                        <div className="p-6 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors">
                            <Zap className="w-12 h-12 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Plataforma Web</h3>
                            <p className="text-gray-600">
                                Gestión y visualización de integraciones en tiempo real
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Product Section */}
            <Product />

            {/* How It Works Section */}
            <section className="py-20 bg-gray-50" id="how-it-works">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-gray-900 mb-4">
                            ¿Cómo Funciona?
                        </h2>
                        <p className="text-xl text-gray-600">
                            Nuestra plataforma ofrece herramientas esenciales para la gestión y análisis de datos B2B
                        </p>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="flex flex-col items-center">
                            <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center mb-2">
                                1
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-sm text-center flex flex-col items-center w-full h-full">
                                <Zap className="w-10 h-10 text-blue-600 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Conéctate</h3>
                                <p className="text-gray-600">
                                    Integramos automáticamente tus datos B2B.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center mb-2">
                                2
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-sm text-center flex flex-col items-center w-full h-full">
                                <Database className="w-10 h-10 text-blue-600 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Consulta</h3>
                                <p className="text-gray-600">
                                    Accede a la data transformada en una base SQL.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center mb-2">
                                3
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-sm text-center flex flex-col items-center w-full h-full">
                                <BarChart3 className="w-10 h-10 text-blue-600 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">Analiza</h3>
                                <p className="text-gray-600">
                                    Construye reportes y toma decisiones basadas en datos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pricing Section */}
            <section className="py-20 bg-white" id="pricing">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-gray-900 mb-4">
                            Planes de Precios
                        </h2>
                        <p className="text-xl text-gray-600">
                            Suplo cobra un precio fijo por cliente e integración B2B. Se aplican descuentos por volumen.
                        </p>
                    </div>
                    <div className="text-center">
                        <button
                            onClick={() => setDemoDialogOpen(true)}
                            className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-blue-700 transition-all transform hover:scale-105 shadow-md hover:shadow-lg flex items-center gap-2 group mx-auto"
                        >
                            Solicitar una Demo
                            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                        </button>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-900 text-gray-300 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid md:grid-cols-4 gap-8">
                        <div>
                            <h3 className="text-white font-semibold text-lg mb-4">Suplo</h3>
                            <p className="text-sm">
                                Transformando datos B2B en decisiones inteligentes
                            </p>
                        </div>
                        <div>
                            <h3 className="text-white font-semibold text-lg mb-4">Producto</h3>
                            <ul className="space-y-2 text-sm">
                                <li>Características</li>
                                <li>Integraciones</li>
                                <li>Precios</li>
                                <li>Documentación</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-white font-semibold text-lg mb-4">Empresa</h3>
                            <ul className="space-y-2 text-sm">
                                <li>Sobre nosotros</li>
                                <li>Blog</li>
                                <li>Casos de éxito</li>
                                <li>Contacto</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-white font-semibold text-lg mb-4">Legal</h3>
                            <ul className="space-y-2 text-sm">
                                <li>Términos y condiciones</li>
                                <li>Privacidad</li>
                                <li>Cookies</li>
                            </ul>
                        </div>
                    </div>
                    <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-center">
                        © 2024 Suplo - Todos los derechos reservados
                    </div>
                </div>
            </footer>

            {/* Demo Dialog */}
            <Dialog open={demoDialogOpen} onClose={() => setDemoDialogOpen(false)}>
                <DialogTitle>Solicitar una Demo</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleDemoRequest}>
                        <TextField
                            name="name"
                            label="Nombre"
                            fullWidth
                            required
                            value={demoForm.name}
                            onChange={handleDemoFormChange}
                        />
                        <TextField
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            required
                            value={demoForm.email}
                            onChange={handleDemoFormChange}
                        />
                        <TextField
                            name="company"
                            label="Empresa"
                            fullWidth
                            required
                            value={demoForm.company}
                            onChange={handleDemoFormChange}
                        />
                        <TextField
                            name="message"
                            label="Mensaje"
                            multiline
                            rows={4}
                            fullWidth
                            value={demoForm.message}
                            onChange={handleDemoFormChange}
                        />
                        <DialogActions>
                            <Button onClick={() => setDemoDialogOpen(false)}>Cancelar</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="bg-blue-600 text-white hover:bg-blue-700"
                            >
                                Enviar Solicitud
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    ¡Gracias! Hemos recibido tu solicitud de demo. Nos pondremos en contacto contigo pronto.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default LandingV2; 